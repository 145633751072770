import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { H2, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/mupo/logomupo.png"
import Top from "../../../images/reference/mupo/top.jpg"
import Martin from "../../../images/reference/mupo/reaction-marketing.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
class Mupo extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro MÜPO | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="MÜPO"
          heading="Reference na marketingové práce pro MÜPO"
          date="2014 - současnost"
        />
        <ReferenceImageText
          right
          img={Top}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti MÜPO"
          link="//mupo.cz/"
        >
          <p>
            Firma MÜPO, spol. s r.o. byla založena v roce 1992 jako
            specializovaná stavební firma zaměřená na výstavbu a rekonstrukce
            všech typů střech, a to v plném rozsahu klempířských, pokrývačských
            a tesařských prací. Postupem času se činnost firmy rozšířila o
            výstavbu kompletních střešních vestaveb a nástaveb včetně realizace
            interiérů suchou výstavbou. Za dobu svojí existence si firma
            upevnila svoji pozici na trhu a stala se renomovanou střechařskou
            firmou v regionu Jižní Moravy, jejíž služby využívá řada velkých
            stavebních společností.
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <H2>Zadání</H2>
            <p className="text-center">
              Cílem jednotlivých kampaní vždy bylo získat nové zájemce na právě
              volné pracovní pozice.
            </p>
          </PaddingWrapper>
          <PaddingWrapper>
            <H2>Rozsah projektu</H2>
            <p>
              V březnu 2014 jsme realizovali přes Facebook Ads a Seznam Sklik
              první placenou náborovou kampaň. Díky jejímu úspěchu jsme dodnes
              realizovali celkem osm kampaní a další budou následovat.
            </p>
          </PaddingWrapper>

          <PaddingWrapper>
            <H2>Historie spolupráce</H2>
            <p>
              Pro stavební firmu MÜPO jsme v minulosti vytvořili webové stránky,
              které jsou však dnes již zastaralé. V blízké budoucnosti proto
              budeme vyvíjet nový web.
            </p>
          </PaddingWrapper>

          <PaddingWrapper>
            <H2>Zhodnocení zadavatele</H2>
            <p>
              Řešili jsme nedostatek zájemců o volná pracovní místa a díky
              kampaním se nám podařilo v krátké době získat nové klempíře,
              tesaře a v sezóně také řadu brigádníků. V roce 2016 budeme
              spolupracovat na propagaci naší předváděcí akce.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <PaddingWrapper>
          <Medailon
            personName="Martin Polášek"
            position="jednatel"
            img={Martin}
          >
            <p>
              Díky kampaním se nám podařilo v krátké době získat nové klempíře,
              tesaře a brigádníky.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Mupo
